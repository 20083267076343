import React, { useEffect, useRef, useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { MicrophoneIcon, StopCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import IfRender from './IfRender';
export default function ASRInput({ name, value, disabled, onChange, className, placeHolder, inputClassName, stop, onFinished }) {
    const inp = useRef(null)
    const {
        transcript,
        listening,
        resetTranscript,
        finalTranscript,
        browserSupportsSpeechRecognition,

    } = useSpeechRecognition({

    })
    useEffect(() => {
        inp?.current?.focus()

        return () => {
            SpeechRecognition.stopListening()
        }
    }, [])



    useEffect(() => {
        if (finalTranscript === '')
            return
        onFinished(finalTranscript)
        return () => {

        }
    }, [finalTranscript])



    return (
        <div className={`relative flex flex-col justify-center items-start ${className}`}>
            <div className='flex flex-col items-start justify-start w-full'>
                <IfRender display={browserSupportsSpeechRecognition} >
                    <span
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (listening) {
                                SpeechRecognition.stopListening()
                            }
                            else {
                                SpeechRecognition.startListening({ language: 'fa-IR', continuous: false })
                            }
                            resetTranscript()
                        }}
                        className='absolute rounded-l-[3rem] left-0 flex justify-center items-center bg-btn p-2 h-12  aspect-square text-white'>
                        {listening ? <StopCircleIcon className='w-5 ' /> : <MicrophoneIcon className='w-5 ' />}

                    </span>
                </IfRender>
                <input type='text' ref={inp}
                    name={name}
                    onChange={e => {
                        if (!listening)
                            onChange(e.target.value)
                    }}
                    value={value}
                    placeholder={listening ? transcript : ''}
                    disabled={disabled}
                    className={`w-full  bg-[#ffffff44] h-12 rounded-[3rem] placeholder:text-[#ffffff88] text-[#ffffffee] ${inputClassName}`} />
            </div>
        </div>
    )
}
