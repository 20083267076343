import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three';

import Stats from 'three/addons/libs/stats.module.js';

import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { KTX2Loader } from 'three/addons/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';

import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';

import { useSelector } from 'react-redux';
import { selectPhonetic } from '../../features/phonetic/phoneticSlice';
import { useInterval } from 'react-interval-hook';

export default function BotFace({ }) {
    const phonetic = selectPhonetic(useSelector(state => state))

    const div = useRef(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [gltfContainer, setGltfContainer] = useState(null);
    const { stop, start, } = useInterval(() => {
        if (!gltfContainer)
            return
        const mesh = gltfContainer.scenes[0];
        const obj = mesh.children[0].children[2].children[0]
        var ar = Object.entries(obj.morphTargetDictionary)
        function change(field, value) {
            var moj = ar.findIndex(x => x[0] === field)
            obj.morphTargetInfluences[moj] = value

        }

        var op = Math.random()
        change('Mouth_Drop_Lower', op);
        change('Mouth_Shrug_Upper', op);
        change('V_Open', op);
        change('V_Dental_Lip', op);
        change('V_Affricate', op);

    }, 100, {
        onFinish: () => {
            if (!gltfContainer)
                return
            const mesh = gltfContainer.scenes[0];
            const obj = mesh.children[0].children[2].children[0]
            var ar = Object.entries(obj.morphTargetDictionary)
            function change(field, value) {
                var moj = ar.findIndex(x => x[0] === field)
                obj.morphTargetInfluences[moj] = value
            }
            var op = 0
            change('Mouth_Drop_Lower', op);
            change('Mouth_Shrug_Upper', op);
            change('V_Open', op);
            change('V_Dental_Lip', op);
            change('V_Affricate', op);
        },
        immediate: true,
        autoStart: false,
        selfCorrecting: true
    })
    const { } = useInterval(() => {
        if (!gltfContainer)
            return
        const mesh = gltfContainer.scenes[0];
        const obj = mesh.children[0].children[2].children[0]
        var ar = Object.entries(obj.morphTargetDictionary)
        function change(field, value) {
            var moj = ar.findIndex(x => x[0] == field)
            obj.morphTargetInfluences[moj] = value
        }

        var op = (Math.floor(Math.random() * 100) % 2 === 0)
        if (op) {
            change('Eye_Blink_L', 1)
            change('Eye_Blink_R', 1)
            setTimeout(() => {
                change('Eye_Blink_L', 0)
                change('Eye_Blink_R', 0)
            }, 100);
        }


    }, 1000, {
        onFinish: () => {

        },
        immediate: true,
        autoStart: true,
        selfCorrecting: true
    })

    useEffect(() => {
        if (!isLoaded)
            init()

        return () => {
        }
    }, [])

    useEffect(() => {
        if (!gltfContainer)
            return
        if (phonetic !== 0) {
            start()
        }
        else {
            stop()
        }

    }, [phonetic])

    function init() {
        setIsLoaded(true)
        let mixer;

        const clock = new THREE.Clock();

        const container = document.createElement('div');

        if (div.current)
            div.current.innerHTML = ""
        container.className = 'z-[1] border-red border-solid border-1 w-full h-full'
        div.current?.append(container)

        const camera = new THREE.PerspectiveCamera(45, div.current?.clientWidth / div.current?.clientHeight, 1, 20);
        const s = 5
        camera.scale.set(1, 1, 1)
        const scene = new THREE.Scene();

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, autoResize: false });

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(div.current?.clientWidth, div.current?.clientHeight);
        // renderer.setSize(600, 300);
        renderer.toneMapping = THREE.ACESFilmicToneMapping;

        container.appendChild(renderer.domElement);

        const ktx2Loader = new KTX2Loader()
            .setTranscoderPath('jsm/libs/basis/')
            .detectSupport(renderer);

        new GLTFLoader()
            .setKTX2Loader(ktx2Loader)
            .setMeshoptDecoder(MeshoptDecoder)
            .load('/face3.glb', (gltf) => {

                setGltfContainer(gltf)
                gltf.scene.scale.set(s, s, s)
                const mesh = gltf.scenes[0];

                mesh.children[0].children[2].children[0].material = new THREE.MeshStandardMaterial({ color: '#d19477' })
                // mesh.children[0].children[2].children[1].material = new THREE.MeshStandardMaterial({color:'#ffffff'})
                mesh.children[0].children[4].material.transparent = true
                mesh.children[0].children[4].material.opacity = .7

                //let options = { morph: 0 };
                //gltf.scene.children[0].children[4].visible = false
                mesh.children[0].children[1].visible = false //teeth


                // let morphChange = (a) => {
                //     //obj.morphTargetInfluences = []
                //     //console.info('OOBBJJ',obj)

                //     //change('V_Tight_O', options.morph);
                //     change('Mouth_Drop_Lower', options.morph);
                //     change('Mouth_Shrug_Upper', options.morph);
                //     change('V_Open', options.morph);
                //     change('V_Dental_Lip', options.morph);
                //     change('V_Affricate', options.morph);
                //     // change('V_Dental_Lip', options.morph);
                //     // change('V_Explosive', options.morph);
                //     // change('V_Lip_Open', options.morph);
                //     // change('V_Tight', options.morph);
                //     // change('V_Wide', options.morph);

                //     //renderer.render(scene, camera)
                //     //render()
                //     //obj.updateMatrixWorld()
                //     //console.info('chaned', a, obj.morphTargetDictionary)
                //     // invalidate()

                //     //obj.updateGeometry()

                // };

                //console.log(mesh.geometry.morphAttributes);


                camera.lookAt(mesh.position);

                scene.add(mesh);

                //mixer = new THREE.AnimationMixer(mesh);

                //mixer.clipAction(gltf.animations[0]).play();

                // GUI

                // const head = mesh.getObjectByName('mesh_2');
                // const influences = head.morphTargetInfluences;

                // const gui = new GUI();
                // gui.close();

                // for (const [key, value] of Object.entries(head.morphTargetDictionary)) {

                //     gui.add(influences, value, 0, 1, 0.01)
                //         .name(key.replace('blendShape1.', ''))
                //         .listen();

                // }

            });

        const environment = new RoomEnvironment(renderer);
        const pmremGenerator = new THREE.PMREMGenerator(renderer);

        //scene.background = new THREE.Color(0x666666);
        scene.environment = pmremGenerator.fromScene(environment).texture;

        const controls = new OrbitControls(camera, renderer.domElement);

        controls.enableDamping = true;
        controls.minDistance = 2.5;
        controls.maxDistance = 50;
        controls.minAzimuthAngle = - Math.PI / 2;
        controls.maxAzimuthAngle = Math.PI / 2;
        controls.maxPolarAngle = Math.PI / 1.8;
        scene.position.set(0.1, 0, 0);
        controls.target.set(0, 0.15, - 0.2);
        controls.enabled = false;
        controls.noPen = false;
        controls.dispose()
        controls.domElement.className = 'hidden'
        //controls.style.display='none'
        const stats = new Stats();
        container.appendChild(stats.dom);
        renderer.setClearColor(0xffffff, 0);

        // Hide the performance monitor
        stats.dom.style.display = 'none';
        renderer.setAnimationLoop(() => {

            const delta = clock.getDelta();
            if (mixer) {

                mixer.update(delta);

            }

            renderer.render(scene, camera);

            controls.update();

            stats.update();

        });

        window.addEventListener('resize', () => {

            //camera.aspect = window.innerWidth / window.innerHeight;
            //camera.updateProjectionMatrix();

            renderer.setSize(div.current?.clientWidth || 10, div.current?.clientHeight || 10);

        });

    }
    return (
        <div ref={div} className='z-[1] border-red border-solid border-1 w-full h-full'>
        </div>
    )
}
