import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getChat, getChats } from '../../api';
import Loading from '../common/Loading';
import { useInterval } from 'react-interval-hook';
import { baseURL } from '../../api/utility';
import toast from 'react-hot-toast';
import left from '../../assets/images/left.svg';
import history from '../../assets/images/history.svg';
import account from '../../assets/images/account.svg';
import moment from 'moment-jalaali';
import botImage from '../../assets/images/bot.svg'
import cogsImage from '../../assets/images/cogs.svg'
import dot from '../../assets/images/dot.svg'
import plus from '../../assets/images/plus.svg'
import plusO from '../../assets/images/plus-o.svg'
import Vira from '../common/Vira';
import { useDispatch } from 'react-redux'
import { setQuestions } from '../../features/questions/questionsSlice';
import { setPhonetic } from '../../features/phonetic/phoneticSlice';

export default function Chat() {
    const { id } = useParams()
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const [notResponse, setNotResponse] = useState(false);
    const bot = useRef(null)
    const audio = useRef(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        handler()
        return () => {

        };
    }, [id]);
    const { start, stop, isActive } = useInterval(
        () => {
            handler()
        },
        1000,
        {
            autoStart: true,
            immediate: true,
            selfCorrecting: true,
            onFinish: () => {

            },
        }
    );



    const handler = async () => {

        if (isSent)
            return
        if (!data)
            setIsLoading(true)

        const { data: response, error } = await getChat(id)

        if (response) {


            setData(response)

            if (response.chat.status === 2) {
                setNotResponse(false)
                stop()
                getChats().then(response => {
                    if (response.data)
                        dispatch(setQuestions(response.data))

                })
            }
            else if (response.chat.status === -1) {
                toast.error('خطایی برای دریافت پاسخ رخ داده است')
                setNotResponse(false)
                stop();
            }
            else {
                setNotResponse(true)
                start();
            }

        }
        else {
            setNotResponse(false)
            stop()
        }
        //bot?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        setIsLoading(false)
        setIsSent(false)

    }
    if (isLoading || !data)
        return <section className=" w-full" >
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <Loading />
            </div>
        </section>
    return (
        <>
            <div>
                <div className='flex sm:hidden flex-row justify-between sm:justify-center w-full px-6 pt-8 gap-0 sm:gap-2'>
                    <Link to='/history' className=' bg-white p-2 rounded-full flex sm:hidden '>
                        <img src={history} />
                    </Link>
                    <div className='text-[#d9d9d980] flex items-center justify-center sm:hidden' >
                        {(() => {

                            moment.loadPersian();
                            const d = new Date()
                            const m = new Intl.DateTimeFormat('fa-IR-u-ca-persian', { month: 'short' }).format(d)
                            return moment().format('jD ') + m + moment().format(' jYYYY')
                        })()}
                    </div>
                    <Link to='/' className='bg-white p-2 rounded-full flex sm:hidden'>
                        <img src={left} />
                    </Link>
                </div>
            </div>
            <div className='grid grid-cols-1 auto-rows-max sm:grid-cols-3 gap-x-1 grid-rows-1 sm:grid-rows-6 w-full  sm:w-[70%] min-w-full sm:min-w-[60rem] sm:mx-auto mt-[10vh] grid-flow-row pb-32 sm:pb-0'>
                <div className='col-span-1 row-span-2 '>
                    <div className='flex sm:hidden justify-center mb-5'>

                        <div className='rounded-3xl bg-white min-h-[8rem] flex p-6 flex-col items-start gap-4'>
                            <div className='flex flex-row items-center justify-center'>
                                <div className='w-10 aspect-square bg-white rounded-full border-solid border-[1px] border-[#7266FE] flex items-center justify-center'>
                                    <img src={account} />
                                </div>
                                <span className='mr-3 text-[#7266FE] text-sm'>
                                    سوال شما
                                </span>
                            </div>
                            <div className='text-right'>
                                {data?.chat.question}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row-span-1 sm:row-span-2 col-span-1 sm:col-span-2 ' >
                    <div className='flex items-center justify-center max-h-full sm:h-[60rem] h-[80rem] cursor-pointer'>
                        <Vira />
                    </div>
                </div>
                <div className='row-span-1 col-span-1 '>
                    <div className='sm:flex hidden flex-col'>
                        <div className='rounded-3xl bg-white min-h-[8rem] flex p-6 flex-col items-start gap-4'>
                            <div className='flex flex-row items-center justify-center'>
                                <div className='w-10 aspect-square bg-white rounded-full border-solid border-[1px] border-[#7266FE] flex items-center justify-center'>
                                    <img src={account} />
                                </div>
                                <span className='mr-3 text-[#7266FE] text-sm'>
                                    سوال شما
                                </span>
                            </div>
                            <div className='text-right'>
                                {data?.chat.question}
                            </div>
                        </div>
                        <Link to='/' className='hidden sm:flex bg-gradient-to-r from-[#7266FE] to-[#5CC3E5] w-full mt-6 h-14 rounded-full text-white items-center justify-center gap-2'>
                            <div>
                                <img src={plusO} />
                            </div>
                            <span>سوال جدید</span>
                        </Link>
                    </div>
                </div>
                <div className='row-span-1 sm:row-span-6 col-span-1 sm:col-span-2  overflow-auto bg-gradient-to-b rounded-3xl from-[#ffffff29] to-[#FFFFFF01] w-[85%] mx-auto'>
                    <ul className='flex flex-col-reverse' ref={bot}>
                        {
                            data?.agent_response.map((item, i) => {
                                return <li key={i} className={`flex items-start gap-[1rem] `}>
                                    <ResponseBody {...item} />
                                    {i === (data.agent_response.length - 1) &&
                                        <div onClick={async e => {
                                            setIsPlaying(pre => !pre)
                                            if (!isPlaying) {
                                                audio.current.play();
                                            }
                                            else {
                                                audio.current.pause()
                                            }
                                        }}
                                            className='hidden'>


                                            <audio className='hidden w-full md:w-4/5 md:order-1 z-5  justify-center items-center '
                                                ref={audio}
                                                onTimeUpdate={e => {
                                                    let a = Math.round(Math.random() * 100)
                                                    dispatch(setPhonetic(a))
                                                }}
                                                onEnded={e => {
                                                    setIsPlaying(false)
                                                    dispatch(setPhonetic(0))
                                                }}
                                                onPause={e => setIsPlaying(false)}
                                                onPlay={() => setIsPlaying(true)}

                                                src={`${baseURL}/${data?.chat.tts}`} autoPlay={true} controls />

                                        </div>}
                                </li>
                            })
                        }
                    </ul>
                    {
                        notResponse &&
                        <section className=" w-full" >
                            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-[10rem] lg:py-0">
                                <Loading />
                            </div>
                        </section>
                    }
                </div>
            </div>
            <Link to='/' className='fixed sm:hidden right-6 bottom-8 bg-[#38F6A7] w-14 aspect-square rounded-full flex items-center justify-center'>
                <img src={plus} />
            </Link>


        </>




    )
}

const ResponseBody = (data) => {
    switch (data.response_type) {
        case "ACTION":
            return <div className='flex flex-col p-6 gap-4 w-full'>
                <div className='flex flex-row justify-start items-center gap-6'>
                    <div className='bg-gradient-to-b from-[#7266FE] to-[#5CC3E5] flex w-10 aspect-square rounded-full items-center justify-center'>
                        <img src={botImage} className='w-5' />
                    </div>
                    <b className='text-[#D9d9d980] text-sm'>نظر ربات</b>
                </div>
                <label className='text-right flex text-base text-white whitespace-pre-line' >
                    باید عبارت "&nbsp;{data.response_body.tool_input}&nbsp;"  را در اینترنت جستجو کنم

                </label>
                <hr className='border-b-1 border-solid border-[#80DFFF]' />
            </div>
        case "SEARCH":
            return (

                <>
                    <div className='flex flex-col p-6 gap-4 w-full'>
                        <div className='flex flex-row justify-start items-center gap-6'>
                            <div className='bg-gradient-to-b from-[#7266FE] to-[#5CC3E5] flex w-10 aspect-square rounded-full items-center justify-center'>
                                <img src={cogsImage} className='w-5' />
                            </div>
                            <b className='text-[#38F6A7] text-sm'> بررسی منابع</b>
                        </div>
                        <ul className='hidden'>
                            {data.response_body.refs.map((item, i) => {
                                return (
                                    <li className='text-right text-[#38F6A7] list-none mb-4 flex flex-row gap-8 justify-start pr-3' key={i}>
                                        <img src={dot} />
                                        <a target='_blank' href={item.href} className='00'>
                                            {/* {decodeURIComponent(item.href)} */}
                                            {item.title}
                                        </a>
                                    </li>
                                )
                            })}</ul>
                        <hr className='border-b-1 border-solid border-[#80DFFF]' />
                    </div>
                </>
            )

        case "TEXT":
            return <div className='flex flex-col p-6 gap-4 w-full mb-6'>
                <div className='flex flex-row justify-start items-center gap-6'>
                    <div className='bg-gradient-to-b from-[#7266FE] to-[#5CC3E5] flex w-10 aspect-square rounded-full items-center justify-center'>
                        <img src={botImage} className='w-5' />
                    </div>
                    <b className='text-[#D9d9d980] text-sm'>{data.response_body.text}</b>
                </div>
                <label className='text-right flex text-base text-white whitespace-pre-line' >
                    {data.response_body.result}

                </label>
                <hr className='border-b-1 border-solid border-[#80DFFF]' />
            </div>
        default:
            return <div className='flex flex-col p-6 gap-4 w-full mb-6'>
                <div className='flex flex-row justify-start items-center gap-6'>
                    <div className='bg-gradient-to-b from-[#7266FE] to-[#5CC3E5] flex w-10 aspect-square rounded-full items-center justify-center'>
                        <img src={botImage} className='w-5' />
                    </div>
                    <b className='text-[#D9d9d980] text-sm'>پاسخ نهایی</b>
                </div>
                <label className='text-right flex text-base text-white whitespace-pre-line' >
                    {data.response_body.result}

                </label>
                <hr className='border-b-1 border-solid border-[#80DFFF]' />
            </div>
    }
}