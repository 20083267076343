import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../features/login/loginSlice';
import userReducer from '../features/user/userSlice';
import questionsReducer from '../features/questions/questionsSlice';
import phoneticReducer from '../features/phonetic/phoneticSlice'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    user: userReducer,
    questions: questionsReducer,
    phonetic: phoneticReducer,
  },
});
