import React from 'react'
import { Link } from 'react-router-dom'
import bot_icon from '../../assets/images/bot-icon.svg'
import { deleteChat, getChats } from '../../api'
import { useDispatch } from 'react-redux'
import { selectQuestion, setQuestions } from '../../features/questions/questionsSlice';
import toast from 'react-hot-toast'
import trash from '../../assets/images/trash.png'
import Loading from '../common/Loading'

export default function QuestionItem({ id, title, answer, status }) {
    const dispatch = useDispatch()
    const deleteHandler = async (e, id) => {
        e.stopPropagation()
        e.preventDefault()
        if (!window.confirm('آیا از حذف این پرسش مطمئنید'))
            return
        const { data, error } = await deleteChat(id);
        if (data) {

            getChats()
                .then(response => {
                    if (response.data) {
                        dispatch(setQuestions(response.data))
                        toast.success('پرسش مورد نظر با موفقیت حذف شد')
                    }
                    else {
                        toast.error('خطا در عملیات حذف رخ داده است')
                    }

                })
                .catch(() => {
                    toast.error('خطا در عملیات حذف رخ داده است')
                })
        }
        else {
            toast.error('خطا در عملیات حذف رخ داده است')
        }

    }
    return (
        <div className='bg-gradient-to-br from-[#FFFFFF60] to-[#FFFFFF20] p-[1px] flex items-center justify-center rounded-3xl w-full min-h-[8rem]'>
            <Link className='bg-[#232323] w-full flex flex-col items-start justify-center gap-4 rounded-3xl  p-6 border-solid border-[1px] border-[#232323]' to={`/${id}`}>
                <div className='truncate w-full text-right text-white font-bold text-base overflow-hidden'>{title}</div>
                <div className='flex flex-row items-center justify-between gap-2 w-full'>
                    <img className='p-[0.44rem] border-solid border-[1px] border-[#D9D9D988] rounded-full' src={bot_icon} />
                    <span className='text-right text-[#D9D9D988] font-normal text-sm line-clamp-2'>
                        {(status !== -1 && status !== 2) ? answer : <div className='w-full'><Loading /></div>}
                    </span>
                    <button onClick={(e) => deleteHandler(e, id)} className='h-[2rem] aspect-square p-[0.34rem] block border-solid border-[1px] border-[#D9D9D988] rounded-full'>
                        <img className=' w-full ' src={trash} />
                    </button>
                </div>
            </Link>
        </div>
    )
}
