import React from 'react'
import left from '../../assets/images/left.svg';
import { Link } from 'react-router-dom';
import plus from '../../assets/images/plus.svg';
import plusO from '../../assets/images/plus-o.svg'
import { applicationOwnerAbout, applicationOwnerAddress, applicationOwnerAddressTitle, applicationOwnerMap } from '../../api/utility';

export default function About() {
    return (
        <div className='px-6 flex flex-col h-[85vh] sm:h-screen overflow-x-hidden overflow-y-auto gap-3'>
            <div className='flex flex-row h-24 items-center justify-between sm:justify-center w-auto sm:w-full'>
                <span className='inline sm:hidden '>

                </span>
                <span className='text-[#D9d9d980] h-24 flex justify-center items-center sm:text-white sm:hidden'>
                    درباره ما
                </span>
                <Link to='/' className='bg-white p-2 rounded-full flex sm:hidden'>
                    <img src={left} />
                </Link>
            </div>
            <div className='flex flex-col gap-2 w-[21.375rem] sm:hidden'>
                <div className='bg-gradient-to-b from-[#ffffff38] to-[#FFFFFF00] rounded-3xl py-8 px-6 text-white'>
                    {/* <h1 className='text-base'>“از من بپرس” چیه؟!</h1> */}
                    <p style={{ fontWeight: '400' }} className='mt-4'>
                        {applicationOwnerAbout}
                    </p>
                    <iframe className="w-[90%] mt-8 aspect-video mx-auto block rounded-lg" src={applicationOwnerMap}></iframe>
                    
                    <h2 className='text-[#38F6A7] mt-6'>{applicationOwnerAddressTitle}</h2>
                    <p className='mt-2'>{applicationOwnerAddress}</p>
                </div>
            </div>
            <div className='hidden flex-row-reverse gap-4  sm:flex mt-28'>
                <div className='bg-gradient-to-b w-[40rem] from-[#ffffff38] to-[#FFFFFF00] rounded-3xl py-8 px-6 text-white'>
                    {/* <h1 className='font-[700]'>“از من بپرس” چیه؟!</h1> */}
                    <p style={{ fontWeight: '400' }} className='mt-4'>
                        {applicationOwnerAbout}
                    </p>
                </div>
                <div className='w-[20rem]'>
                    <iframe className="w-[90%]  aspect-video mx-auto block rounded-lg" src={applicationOwnerMap}></iframe>
                    <h2 className='text-[#38F6A7] mt-6'>{applicationOwnerAddressTitle}</h2>
                    <p className='mt-2 text-white'>
                        {applicationOwnerAddress?.split('\n').map((x, i) => <p key={i}>{x}</p>)}
                    </p>
                    <Link to='/' className='hidden sm:flex bg-gradient-to-r from-[#7266FE] to-[#5CC3E5] w-full mt-6 h-14 rounded-full text-white items-center justify-center gap-2'>
                        <div>
                            <img src={plusO} />
                        </div>
                        <span>سوال جدید</span>
                    </Link>
                </div>
            </div>
            <Link to='/' className='fixed sm:hidden right-6 bottom-8 bg-[#38F6A7] w-14 aspect-square rounded-full flex items-center justify-center'>
                <img src={plus} />
            </Link>
        </div>
    )
}
