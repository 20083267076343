import React, { useEffect, useRef, useState } from 'react';
import { createChat, getChats } from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setQuestions } from '../../features/questions/questionsSlice';
import toast from 'react-hot-toast';
import history from '../../assets/images/history.svg';
import microphone from '../../assets/images/microphone.svg';
import sent from '../../assets/images/sent.svg';
import Vira from '../common/Vira';
import { setLogin } from '../../features/login/loginSlice';
import { setUser } from '../../features/user/userSlice';
import { applicationDescription, applicationLogo, applicationName, applicationOwner } from '../../api/utility';
import ASRInput from '../common/ASRInput';

export default function Home() {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear()
        dispatch(setLogin(false))
        dispatch(setUser(null))
        navigate('/')
    }

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [question, setQuestion] = useState("");

    const handler = async e => {
        if (e)
            e.preventDefault();
        if (!question || question === "") {
            toast.error('شما هیچ سوالی نپرسیده اید')
            return
        }
        setIsLoading(true)
        chatHandler(question)
    }
    const chatHandler = async (question) => {
        const { data, error } = await createChat({ question })
        if (data) {
            getChats().then(response => {
                if (response.data)
                    dispatch(setQuestions(response.data))

            })
            setIsLoading(false)
            navigate('./' + data.id)
        }
        else {
            if (error?.response?.status === 402)
                toast.error('شما مجاز به ارسال سوال نیستید')
            else
                toast.custom((t) => (
                    <div className={`bg-red-900 text-white px-6 py-4 shadow-md rounded-full ${t.visible ? 'animate-enter' : 'animate-leave'}`} >
                        خطا در انجام عملیات
                    </div>
                ))
        }
    }
    return (
        <div className='flex flex-col w-full items-center justify-between h-full'>
            <div className='flex flex-row justify-between sm:justify-center w-full px-6 pt-8 gap-0 sm:gap-2'>
                <div className='text-white flex flex-col justify-center items-center gap-4'>
                    <img src={applicationLogo} className='w-[5rem]' />
                    <span>
                        {applicationOwner}
                    </span>
                </div>
                <div className='text-[#38F6A7] inline sm:hidden cursor-pointer' onClick={logout}>
                    خروج از حساب
                </div>
            </div>
            <div className='w-full items-center justify-center flex flex-col gap-4'>
                <div className='w-48 aspect-square rounded-full flex items-center justify-center'>
                    <Vira />
                </div>
                <h1 className='text-white text-4xl'>{applicationName}</h1>
                <h3 className='text-[#d4d4d4d1] text-xl'>{applicationDescription}</h3>
                <Link to='/history' className='bg-white h-8 rounded-full flex flex-row justify-center p-1 relative w-[7.68rem] sm:hidden' >
                    <img src={history} className='w-5 aspect-square absolute right-[0.38rem]' />
                    <span className='text-[#010D08] absolute left-[1.88rem]'>
                        تاریخچه
                    </span>
                </Link>
                <Link to='/about' className='text-white pt-3 flex sm:hidden' >
                    درباره ما
                </Link>
            </div>
            <form onSubmit={handler} className='mb-8 mb- flex flex-row gap-2 items-center justify-center'>
                <button type='submit' className='bg-gradient-to-r from-[#7266FE] to-[#5CC3E5] aspect-square rounded-full w-14 flex items-center justify-center'>
                    <img src={sent} />
                </button>
                <div className='relative flex flex-row items-center justify-center w-[70%] sm:w-[26rem] cursor-pointer'>
                    <ASRInput
                        onChange={e => setQuestion(e)}
                        onFinished={(e) => chatHandler(e)}
                        value={question}
                        disabled={isLoading}
                        className='w-full'
                    />
                </div>
            </form>
            <div className='hidden sm:flex mb-8 sm:mb-0 w-[300px] items-center justify-between pb-6'>
                <Link to='/about' className='text-white' >
                    درباره ما
                </Link>
                <div className='text-[#38F6A7] cursor-pointer' onClick={logout}>
                    خروج از حساب
                </div>

            </div>
        </div>
    );
};
