import React, { useEffect, useState } from 'react'
import sidetoggle from '../../assets/images/sidebar toggle.png'//'../../assets/images/sidebar toggle.png';
import {  getChats } from '../../api';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { selectQuestion, setQuestions } from '../../features/questions/questionsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from "react-router";
import trash from '../../assets/images/trash.png';
import { setLogin } from '../../features/login/loginSlice';
import QuestionItem from './QuestionItem';
import left from '../../assets/images/left.svg';
import plus from '../../assets/images/plus.svg';
import history from '../../assets/images/history.svg';

export default function Sidenav() {
    const [id, setId] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const questions = selectQuestion(useSelector(state => state))
    const dispatch = useDispatch()
    const handler = () => {
        getChats().then(response => {
            if (response.data)
                dispatch(setQuestions(response.data))

        })
    }
    useEffect(() => {
        handler()
        return () => {

        };
    }, []);
    useEffect(() => {
        const url = location.pathname.split('/')
        if (url.length > 0)
            setId(parseInt(url[1]))
        else
            setId(null)

        return () => {

        };
    }, [location.pathname]);
   
    return (
        <div className='px-6 flex flex-col h-[85vh] sm:h-screen overflow-x-hidden overflow-y-auto gap-3'>
            <div className='flex flex-row h-24 items-center justify-between sm:justify-center w-auto sm:w-full'>
                <span className='inline sm:hidden '>

                </span>
                <span className='text-[#D9d9d980] h-24 flex justify-center items-center sm:text-white'>
                    <img src={history} className='history-white ml-2 w-7'  />
                    تاریخچه سوالات
                </span>
                <Link to='/' className='bg-white p-2 rounded-full flex sm:hidden'>
                    <img src={left} />
                </Link>
            </div>
            <div className='flex flex-col gap-2 w-[21.375rem]'>
                {
                    questions
                        .map((item, i) => {
                            return <QuestionItem key={i} title={item.chat.question} id={item.chat.id} answer={item.answer} status={item.status} />
                        })
                }
            </div>
            <Link to='/' className='fixed sm:hidden right-6 bottom-8 bg-[#38F6A7] w-14 aspect-square rounded-full flex items-center justify-center'>
                <img src={plus} />
            </Link>
        </div>
    )
}
