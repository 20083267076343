import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

export const phoneticSlice = createSlice({
  name: 'phonetic',
  initialState,
  reducers: {
    setPhonetic: (state, action) => {
      state.value = action.payload;
    }
  },

});

export const { setPhonetic } = phoneticSlice.actions;
export const selectPhonetic = (state) => state.phonetic.value;

export default phoneticSlice.reducer;
